import React, { useEffect, useState } from 'react'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import useQuickTranslator from '../hooks/useQuickTranslator'
import { Modal } from 'react-bootstrap'

const CONSENT_COOKIE = 'gdpr-consent-google-tagmanager'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const GDPRCookieConsent = () => {
    const t = useQuickTranslator()
    const location = useLocation()

    const [consentModalOpen, setConsentModalOpen] = useState(false)
    useEffect(() => {
        // If consent cookie has not been set, open modal immediately
        if (getCookieConsentValue(CONSENT_COOKIE) === undefined && !/Chrome-Lighthouse/i.test(navigator.userAgent)) {
            setConsentModalOpen(true)
        }
    }, [location.pathname])

    const onAcceptHandler = () => {
        setConsentModalOpen(false)
        // Immediately start tracking on accept, without route change or refresh
        initializeAndTrack(location)
    }

    const extraCookieOptions = {}

    // If a cookie domain has been set in the .env variables, use that. For local development (and other environments)
    // we want to use host only cookie, to prevent banner from always popping up. But for the live site, we want to
    // set our domain, to allow the cookie to be read on all subdomains.
    if (process.env.GATSBY_GDPR_COOKIE_DOMAIN) {
        extraCookieOptions.domain = process.env.GATSBY_GDPR_COOKIE_DOMAIN
    }

    return (
        <StyledModal show={consentModalOpen} size='lg' onHide={() => {}}>
            <CookieConsent
                onAccept={onAcceptHandler}
                onDecline={() => setConsentModalOpen(false)}
                cookieName={CONSENT_COOKIE}
                enableDeclineButton
                extraCookieOptions={extraCookieOptions}
                expires={1000}
                contentClasses='modal-body'
                buttonWrapperClasses='modal-footer pb-4 pt-2 text-center justify-content-center border-top-0'
                declineButtonClasses='btn btn-link my-2 text-uppercase'
                buttonClasses='btn btn-success text-white shadow my-2 py-3 text-uppercase'
                buttonText={t('cookieconsent.accept')}
                declineButtonText={t('cookieconsent.decline')}
                disableStyles>
                <p className='h2 mb-4'>
                    <FormattedMessage id='cookieconsent.heading' />
                </p>
                <p>
                    <FormattedMessage
                        id='cookieconsent.content.p'
                        values={{
                            a: chunks => (
                                // eslint-disable-next-line
                                <a href='https://www.sirumobile.com/cookie-policy' target='_blank' rel='noopener'>
                                    {chunks}
                                </a>
                            ),
                        }}
                    />
                </p>
            </CookieConsent>
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    letter-spacing: 1.5px;

    .h2 {
        font-weight: 600;
        font-size: 1.9rem;
    }

    button {
        font-weight: bold;
        font-size: 0.9rem;
        letter-spacing: 1.5px;
        padding-left: 5rem;
        padding-right: 5rem;
    }

    a {
        text-decoration: none;
    }

    button.btn-link {
        color: var(--bs-gray);
        text-decoration: none;
    }

    .modal-body {
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;

        background-color: var(--bs-light);

        padding: clamp(2rem, 6vw, 4rem) clamp(1.5rem, 6vw, 6rem);
    }
`

export default GDPRCookieConsent
