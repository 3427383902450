const messages_sv = {
    products: 'Produkter',
    about: 'Om oss',
    contact_us: 'Kontakta oss',
    related: 'Relaterad',
    'read_more.p':
        'Siru Mobile är ett företag som erbjuder mobila betalningslösningar. Med Siru Mobile kan kunder betala för produkter och tjänster med mobiltelefonen. Kunden behöver inte ha kontanter eller kreditkort för att göra betalningar.',
    'read_more.button': 'Läs mer',
    search: 'Sök',
    'cookieconsent.heading': 'Information om cookies',
    'cookieconsent.content.p':
        'Vår webbplats använder cookies från första och tredje part för att kunna erbjuda den bästa möjliga användarupplevelse när du besöker oss. Vi använder oss av cookies för prestanda och analys endast ifall du accepterar dem genom att välja Acceptera. Genom att välja Avvisa nekar du användningen av cookies för prestanda och analys, men vi kan fortfarande använda nödvändiga förstapartscookies om du fortsätter att surfa på vår webbplats. <a>Här</a> finner du vår Cookie policy.',
    'cookieconsent.decline': 'Avvisa',
    'cookieconsent.accept': 'Acceptera',
    'footer.logo_punchline': 'Det är som en app, men bättre.',
    'footer.company': 'Bolag',
    'footer.company.careers': 'Karriärer',
    'footer.learn_more': 'Läs mer',
    'footer.learn_more.faq': 'FAQ',
    'footer.learn_more.terms_of_use': 'Villkor',
    'footer.learn_more.privacy_policy': 'Integritetspolicy',
    'footer.learn_more.cookie_policy': 'Cookiepolicy',
    'footer.developers': 'Mjukvaruutvecklare',
}

export default messages_sv
