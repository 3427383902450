import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { StaticImage } from 'gatsby-plugin-image'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = () => {
    return (
        <StyledFooter>
            <Container>
                <Row className='text-white py-5'>
                    <Col md='6' lg='3'>
                        <StaticImage
                            src='../images/logos/sirumobile_logo_white.png'
                            height={90}
                            placeholder='blurred'
                            className='mb-3'
                            alt='logo'
                        />
                        <p>
                            <FormattedMessage id='footer.logo_punchline' />
                        </p>
                    </Col>
                    <Col md='6' lg='3' className='mb-4'>
                        <p className='mb-4'>
                            <strong>
                                <FormattedMessage id='footer.company' />
                            </strong>
                        </p>
                        <p>
                            {/* eslint-disable-next-line */}
                            <a href='https://www.sirumobile.com/about' target='_blank' className='d-inline-block mb-3'>
                                <FormattedMessage id='about' />
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/careers'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                <FormattedMessage id='footer.company.careers' />
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/contact'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                <FormattedMessage id='contact_us' />
                            </a>
                        </p>
                    </Col>
                    <Col md='6' lg='3' className='mb-4'>
                        <p className='mb-4'>
                            <strong>
                                <FormattedMessage id='products' />
                            </strong>
                        </p>
                        <p>
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/sirupay'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                SiruPay
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/siruqr'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                SiruQR
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/siruid'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                SiruID
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/sirusms'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                SiruSMS
                            </a>
                        </p>
                    </Col>
                    <Col md='6' lg='3' className='mb-4'>
                        <p className='mb-4'>
                            <strong>
                                <FormattedMessage id='footer.learn_more' />
                            </strong>
                        </p>
                        <p>
                            {/* eslint-disable-next-line */}
                            <a href='https://www.sirumobile.com/faq' target='_blank' className='d-inline-block mb-3'>
                                <FormattedMessage id='footer.learn_more.faq' />
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/products/sirupay/demo'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                Demo
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/terms-and-conditions'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                <FormattedMessage id='footer.learn_more.terms_of_use' />
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/privacy-policy'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                <FormattedMessage id='footer.learn_more.privacy_policy' />
                            </a>
                            <br />
                            {/* eslint-disable-next-line */}
                            <a
                                href='https://www.sirumobile.com/cookie-policy'
                                target='_blank'
                                className='d-inline-block mb-3'>
                                <FormattedMessage id='footer.learn_more.cookie_policy' />
                            </a>
                        </p>
                    </Col>
                </Row>
                <Row className='pb-5'>
                    <Col md='6' className='text-secondary mb-3'>
                        Copyright {new Date().getFullYear()} &copy; sirumobile.com
                    </Col>
                    <Col md='2'>
                        {/* eslint-disable-next-line */}
                        <a
                            href='https://www.sirumobile.com/developers'
                            target='_blank'
                            rel='noopener'
                            className='d-inline-block mb-3'>
                            <FormattedMessage id='footer.developers' />
                        </a>
                    </Col>
                </Row>
            </Container>
        </StyledFooter>
    )
}

const StyledFooter = styled.footer`
    background-color: var(--bs-dark);

    a {
        color: var(--bs-gray);
        text-decoration: none;
        &:hover {
            color: var(--bs-light);
        }
    }
`

export default Footer
