import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  .btn {
    box-shadow: 0 0 27px 0 rgba(0,0,0,0.12);
  }
  
  .btn-link {
    box-shadow: none;
  }
  
  h2,
  .h2,
  h3,
  .h3 {
    margin-top: 2.5rem;
  }
  
  form .form-control, form .btn {
    font-size: 1.2rem;
    letter-spacing: 1.5px;
  }

  .dark-background-overlay {
    background-color: rgba(0, 0, 0, 0.24);
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  }

  .full-screen-jumbo {
    min-height: min(70vh, 50em);

    .name {
      font-family: 'Open Sans', sans-serif;
      font-size: 1.2em;
      letter-spacing: 2px;
      font-weight: 400;
    }

    .message {
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 2.8em;
      line-height: 1.2;

      strong {
        font-family: 'proxima-nova', sans-serif;
        font-weight: 400;
      }
    }
  }
  
  .z-index-n1 {
    z-index: -1;
  }
`

export default GlobalStyles
