const messages_de = {
    products: 'Produkte',
    about: 'Über uns',
    contact_us: 'Kontaktiere uns',
    related: 'Verbunden',
    search: 'Suche',
    'cookieconsent.heading': 'Sie kontrollieren Ihre Daten',
    'cookieconsent.content.p':
        'Unsere Website verwendet Cookies von Erst- und Drittanbietern, die die Funktion der Website unterstützen, damit wir Ihnen die bestmögliche Benutzererfahrung bieten können. Wir verwenden analytische Cookies nur, wenn Sie dem zustimmen, indem Sie auf Akzeptieren klicken. Indem Sie auf „Ablehnen“ klicken, lehnen Sie die Verwendung von analytischen Cookies ab, aber wir können dennoch notwendige First-Party-Cookies verwenden, wenn Sie weiterhin auf unserer Website surfen. Unsere Cookie-Richtlinie finden Sie <a>hier</a>.',
    'cookieconsent.decline': 'Ablehnen',
    'cookieconsent.accept': 'Annehmen',
    'footer.logo_punchline': 'Es ist wie eine App, aber besser.',
    'footer.company': 'Gesellschaft',
    'footer.company.careers': 'Karriere',
    'footer.learn_more': 'Mehr erfahren',
    'footer.learn_more.faq': 'FAQ',
    'footer.learn_more.terms_of_use': 'Nutzungsbedingungen',
    'footer.learn_more.privacy_policy': 'Datenschutz-Bestimmungen',
    'footer.learn_more.cookie_policy': 'Cookie-Richtlinie',
    'footer.developers': 'Entwickler',
}

export default messages_de
