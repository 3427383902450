import { Col, Container, Row } from 'react-bootstrap'
import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import messages_en from '../data/translations/en'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReadMoreSection = () => {
    return (
        <SectionStyles className='bg-light py-5'>
            <Container>
                <Row>
                    <Col lg='7'>
                        <div className='mb-3 py-4 fs-1 dots'>&nbsp;. . .</div>
                        <p className='mb-5'>
                            <FormattedMessage id='read_more.p' defaultMessage={messages_en['read_more.p']} />
                        </p>

                        <a
                            href='https://www.sirumobile.com'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='btn btn-outline-dark px-5 shadow-none fs-4 mb-5'>
                            <FormattedMessage id='read_more.button' defaultMessage={messages_en['read_more.button']} />
                        </a>
                    </Col>
                </Row>
            </Container>
        </SectionStyles>
    )
}

export default ReadMoreSection

const SectionStyles = styled.section`
    .dots {
        letter-spacing: 2px;
    }

    p {
        font-size: 1.15em;
    }

    a {
        letter-spacing: 1.5px;
    }
`
