const messages_en = {
    products: 'Products',
    about: 'About us',
    contact_us: 'Contact us',
    related: 'Related',
    'read_more.p':
        'Siru Mobile is a payment company that allows customers to make payments using their mobile phone. Customers do not need to have cash or a credit card in order to make a payment using Siru Mobile.',
    'read_more.button': 'Read more',
    search: 'Search',
    'cookieconsent.heading': 'You control your data',
    'cookieconsent.content.p':
        'Our website uses first and third party cookies that help the website function, so that we can provide you with the best possible user experience. We will only use analytical cookies if you consent to it by clicking on Accept. By clicking Reject you deny the use of analytical cookies, but we may still use necessary first party cookies if you continue browsing our website. Our Cookie policy is found <a>here</a>.',
    'cookieconsent.decline': 'Reject',
    'cookieconsent.accept': 'Accept',
    'footer.logo_punchline': "It's like an app, but better.",
    'footer.company': 'Company',
    'footer.company.careers': 'Careers',
    'footer.learn_more': 'Learn more',
    'footer.learn_more.faq': 'FAQ',
    'footer.learn_more.terms_of_use': 'Terms of use',
    'footer.learn_more.privacy_policy': 'Privacy policy',
    'footer.learn_more.cookie_policy': 'Cookie policy',
    'footer.developers': 'Developers',
}

export default messages_en
