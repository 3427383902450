import React from 'react'
import BaseIntlProvider from './src/components/BaseIntlProvider'
import BaseStyles from './src/styles/BaseStyles'
import Layout from './src/components/Layout'

// This file is run in the browser. No ssr here.

/**
 * Gatsby Browser API: wrapPageElement
 *
 * Allow a plugin to wrap the page element.
 *
 * @param element The “Page” React Element built by Gatsby.
 * @param props Props object used by page.
 * @returns {JSX.Element}
 */
export const wrapPageElement = ({ element }) => {
    return (
        <BaseIntlProvider>
            <BaseStyles />
            <Layout>{element}</Layout>
        </BaseIntlProvider>
    )
}
