import React from 'react'
import { Navbar } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const TopNavBar = () => {
    return (
        <section className='bg-dark'>
            <StyledNavbar className={`container text-white py-3 px-2`}>
                <Navbar.Brand>
                    <StaticImage
                        src='../images/logos/sirumobile_logo.png'
                        height={50}
                        placeholder='blurred'
                        className='d-inline-block align-top'
                        alt='logo'
                    />
                </Navbar.Brand>
            </StyledNavbar>
        </section>
    )
}

const StyledNavbar = styled(Navbar)`
    font-weight: 600;
    font-size: 0.9em;

    button,
    a {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: var(--bs-white) !important;
    }

    a:hover {
        color: var(--bs-gray) !important;
    }
`

export default TopNavBar
