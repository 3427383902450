import { useIntl } from 'react-intl'

/**
 * useQuickTranslator -hook
 *
 * Just to get a shorter and simpler syntax for intl.formatMessage.
 *
 * @returns {*}
 * @constructor
 */
const useQuickTranslator = () => {
    const intl = useIntl()

    const t = id =>
        intl.formatMessage({
            id,
        })

    return t
}

export default useQuickTranslator
