import React from 'react'
import TopNavBar from './TopNavBar'
import Footer from './Footer'
import GDPRCookieConsent from './GDPRCookieConsent'
import ReadMoreSection from './ReadMoreSection'

const Layout = ({ children }) => {
    return (
        <>
            <TopNavBar />

            {children}

            <ReadMoreSection />

            <GDPRCookieConsent />
            <Footer />
        </>
    )
}

export default Layout
