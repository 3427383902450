const messages_fi = {
    products: 'Tuotteet',
    about: 'Meistä',
    contact_us: 'Ota yhteyttä',
    related: 'Liittyvät',
    'read_more.p':
        'Siru Mobile on maksupalveluntarjoaja joka mahdollistaa maksamisen käyttäjän puhelinliittymällä. Asiakkailla ei tarvitse olla käteistä tai luottokorttia maksun tekemiseen Siru Mobilella.',
    'read_more.button': 'Lue lisää',
    search: 'Hae',
    'cookieconsent.heading': 'Tietoa sivustolla käytetyistä evästeistä',
    'cookieconsent.content.p':
        'Verkkosivustomme käyttää ensimmäisen ja kolmannen osapuolen evästeitä asiakaskokemuksen parantamiseksi. Käytämme analytiikkaevästeitä vain, jos hyväksyt ne. Mikäli kieltäydyt analytiikkaevästeistä, saatamme silti käyttää välttämättömiä ensimmäisen osapuolen evästeitä jos jatkat sivuston selaamista. Evästekäytäntömme löytyy <a>täältä</a>.',
    'cookieconsent.decline': 'Hylkää',
    'cookieconsent.accept': 'Hyväksy',
    'footer.logo_punchline': 'Kuten sovellus, mutta parempi.',
    'footer.company': 'Yhtiö',
    'footer.company.careers': 'Työpaikat',
    'footer.learn_more': 'Lue lisää',
    'footer.learn_more.faq': 'UKK',
    'footer.learn_more.terms_of_use': 'Käyttöehdot',
    'footer.learn_more.privacy_policy': 'Yksityisyysseloste',
    'footer.learn_more.cookie_policy': 'Evästekäytäntö',
    'footer.developers': 'Kehittäjät',
}

export default messages_fi
